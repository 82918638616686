<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>{{ userInfo.trueName }}考试详情</h5>
    </div>

    <div class="paper_title">
      <div>{{ paperInfo.title }}</div>
      <div class="exam_time" v-if="paperInfo.type == 1"><span>总题目：{{ totalNum }}</span>
        <span>考试成绩：{{ examRecord.examScore }}分</span>
      </div>
    </div>
    <div class="exam_swiper">
      <div class="uni-padding-wrap">
        <div class="page-section swiper">
          <div class="page-section-spacing">
            <div v-for="(item, index) in questionList" :key="item.id">
              <div style="margin-bottom: 20px">
                <div class="exam_title">{{ index + 1 }}.
                  <span v-if="item.typeId == 1">（单选）</span>
                  <span v-if="item.typeId == 2">（多选）</span>
                  <span v-if="item.typeId == 3">（问答）</span>
                  {{ item.content }}
                </div>
                <div class="exam_answer">
                  <div class="uni-list">
                    <div v-if="item.typeId == 1">
                      <label class="uni-list-cell uni-list-cell-pd" v-for="(citem, index) in item.options"
                        :key="citem.optionId">
                        <div style="word-break:break-all"
                          :class="item.answers && item.answers[0] == citem.optionId ? 'hasCheck' : ''">
                          <span>{{ index + 1 }}、</span>{{ citem.content }}
                        </div>
                      </label>

                    </div>
                    <div v-if="item.typeId == 2 && item.answers">
                      <label class="uni-list-cell uni-list-cell-pd" v-for="(citem, index) in item.options"
                        :key="citem.optionId">
                        <div style="word-break:break-all;"
                          :class="item.answers && test(citem, index, item) ? 'hasCheck' : ''">
                          {{ index + 1 }}、{{ citem.content }}</div>
                      </label>
                    </div>
                    <textarea v-if="item.typeId == 3 && item.answers" :value="item.answers[0]"
                      style="width: 100%;background-color: #fafafa;margin-top: 10px;padding: 10px;" :disabled="true" />
                  </div>
                </div>

                <div v-if="paperInfo.type == 1">
                  <div class="answer_key_box">
                    <div class="note_box">解释：{{ item.analysis }}</div>
                  </div>
                  <div class="answer_key_box">
                    <div class="note_box" v-if="item.options != null">
                      正确答案：
                      <span v-for="citem in item.options" :key="citem.id">
                        <span v-if="citem.answer">
                          {{ citem.content }}
                        </span>
                      </span>
                    </div>
                    <div v-else>正确答案：{{ item.answer }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    userInfo: {
      type: Object,
      required: true
    }
  },
  name: "details-info",
  data() {
    return {
      indicatorDots: true,
      autoplay: false,
      duration: 500,
      curNum: 1,
      current: 0,
      totalNum: null,
      hint: '',
      questionList: {},
      paperInfo: {},
      examRecord: {},
      exam_score: 0,
    }
  },
  created() {
    //this.getUserTaskInfo(this.userInfo.paperId,this.userInfo.planId,this.userInfo.userId)
  },
  methods: {
    async getUserTaskInfo(paperId, planId, userId) {
      var _this = this
      try {
        let res = await api.userTaskInfoGet(paperId, planId, userId)
        if (res.code == 200) {
          var totalScores = 0
          _this.paperInfo = res.data.paper
          _this.examRecord = res.data.examRecord
          _this.questionList = res.data.question
          _this.totalNum = _this.questionList.length
          _this.examPlan = res.data.examPlan
          if (_this.totalNum > 0) {
            _this.questionList.forEach(item => {
              totalScores = totalScores + item.score
            })
          }
          let questionAnswerJson = []
          if (_this.examRecord.questionAnswerJson != null) {
            questionAnswerJson = JSON.parse(_this.examRecord.questionAnswerJson)
          }
          _this.questionList.forEach(item => {
            questionAnswerJson.forEach(citem => {
              if (item.id == citem.questionId) {
                item.answers = citem.answers
              }
            })
          })
        }
      } catch (e) {

      }
    },
    test(citem, index, item) {
      console.log(citem, index, item)
      for (let i in item.answers) {
        if (citem.optionId == item.answers[i]) {
          return true
        }
      }
    },
  },
  watch: {
    userInfo: {
      handler(val) {
        this.getUserTaskInfo(val.paperId, val.planId, val.userId)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.paper_title {
  padding: 10px 15px;
  margin: 10px 15px;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
}

.paper_title div {
  text-align: center;
  line-height: 26px;
  font-size: 18px;
}

.exam_time {
  width: 200px;
  text-align: right;
  text-align: center;
  width: 100%;
  font-size: 12px !important;
}

.swiper {
  height: 350px;
}

.exam_swiper {
  margin: 10px 15px;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  height: calc(100% - 108px);
  overflow: auto;
}

.exam_title {
  font-weight: bold;
}

.uni-list-cell {
  display: flex;
  line-height: 30px;
}

.hasCheck {
  color: #0bf3c2;
}

.answer_key_box {
  margin-top: 10px;
  background-color: #fff;
  padding: 5px;
  line-height: 30px;
  border-top: 1px solid #fafafa;
}

.answer_key_box view {
  color: #333;
  font-size: 26px;
}

.note_box {
  color: #666;
}

.next_box {
  display: flex;
  position: absolute;
  bottom: 20px;
  line-height: 64px;
  text-align: center;
  background-color: #13CD81;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 15px;
  border-radius: 5px;
  color: #fff;
  padding: 10px 0;
}

.next_box view:first-child {
  flex: 1;
}

.next_box view:last-child {
  flex: 1;
}

.next_box view:nth-child(2) {
  position: relative;
  width: 120px;
}

.voice_box_cont {
  position: absolute;
  width: 120px;
  height: 120px;
  top: -80px;
  background-color: #0EA567;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.voice_box {
  width: 120px;
}

.exam_answer {
  position: relative;
}

.tips {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 64px;
  height: 64px;
}

.tips image {
  width: 64px;
  height: 64px;
}

.modelMask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.exam_info {
  width: 90%;
  height: auto;
  margin: 40% 5% 0 5%;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px 0;
}

.exam_score {
  line-height: 64px;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #efefef;
}

.exam_score span {
  font-size: 50px;
}

.btn_class {
  display: flex;
  padding: 20px 0;
}

.btn_class button {
  color: #fff;
  background: #06B768;
  font-size: 30px;
}

.answer-card {
  width: 750px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: 0;
  margin-bottom: calc(120px + env(safe-area-inset-bottom));
}
</style>

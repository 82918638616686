<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>考试任务分析</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input placeholder="试卷名称" v-model="searchForm.paperTitle"></el-input>
        </div>
        <div class="input_box" v-if="this.$store.state.role == 'admin'">
          <span>地区：</span>
          <!--省市三级联动-->
          <!--<el-cascader-->
          <!--placeholder="请选择"-->
          <!--v-model="searchForm.code"-->
          <!--:options="options"-->
          <!--ref="myCascader"-->
          <!--clearable-->
          <!--@change="getArea"-->
          <!--&gt;-->
          <!--</el-cascader>-->
          <el-select v-model="searchForm.code" @change="getAreaCode" @focus="getArea" placeholder="请选择地区" filterable default-first-optio @blur="selectBlur($event)"
            clearable>
            <el-option v-for="item in options" :value="item.regionCode" :label="item.area" :key="item.regionCode" />
          </el-select>
        </div>
        <div class="input_box" v-if="this.$store.state.role == 'admin'">
          <span>社区：</span>
          <el-select v-model="searchForm.communityId" clearable placeholder="请选择">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchBtn">搜索</el-button></div>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="selection" width="45" />
        <el-table-column fixed prop="id" label="ID" width="100" />
        <el-table-column prop="title" label="任务名称" />
        <el-table-column prop="paperTitle" label="试卷名称" />
        <el-table-column prop="questionSize" label="试题数量" width="80" />
        <el-table-column prop="userScore" label="试卷总分" width="80" />
        <el-table-column prop="userCount" label="已推送人数" width="100" />
        <el-table-column prop="completedCount" label="已答题人数" width="100" />
        <el-table-column prop="rate" label="考试覆盖率" width="100" />
        <el-table-column prop="createTime" label="创建时间" width="200" />
        <el-table-column prop="creatorName" label="创建人" width="200" />
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="任务详情" placement="top">
              <el-button @click="taskInfo(scope.row)" type="primary" icon="el-icon-tickets" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="数据分析" placement="top">
              <el-button @click="analysis(scope.row)" type="primary" icon="el-icon-data-analysis" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!--查看详情-->
    <el-dialog :visible.sync="editDialog" width="90%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <info-box :operateBox="operateBox" :key="timer"></info-box>
    </el-dialog>
    <!--考试任务分析-->
    <el-dialog :visible.sync="analysisDialog" width="60%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <analysis :exameInfo="exameInfo" :key="timer"></analysis>
    </el-dialog>
  </div>
</template>

<script>
import InfoBox from './components/info-box'
import Analysis from './components/analysis'
import { chinaArea } from '@/utils/china-area-data'
import api from '@/api/index'
export default {
  name: 'index',
  components: {
    Analysis,
    InfoBox
  },
  data () {
    return {
      options: chinaArea,
      communityList: [],
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '任务详情',
        operate: 'add',
        id: null
      },
      searchForm: {
        paperTitle: '',
        communityId: null,
        communityIds: null,
        code: null
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      timer: null,
      analysisDialog: false,
      exameInfo: {}
    }
  },
  created () {
    this.fetchUserPower()
  },
  methods: {
    selectBlur (res) {
      console.log(res)
    },
    // 根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower () {
      try {
        const res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getList(this.searchForm, this.pageBox)
        }
      } catch (e) {

      }
    },
    // 获取社区
    getArea () {
      this.options = this.$store.state.userArea
    },
    async getAreaCode (val) {
      this.searchForm.communityId = null
      const data = {
        code: val
      }
      const pageBox = {
        pageNum: 1,
        pageSize: 99999
      }
      const res = await api.getCommunitySystemUser(data, pageBox)
      if (res.code == 200 && res.rows) {
        this.communityList = res.rows
        // if (this.communityList.length > 0) {
        //   this.searchForm.communityId = res.rows[0].id
        // }
      } else {
        this.communityList = []
      }
    },
    async getList () {
      try {
        console.log(this.searchForm)
        const res = await api.getExamTaskStaticList(this.searchForm, this.pageBox)
        if (res.code == 200) {
          console.log(res)
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {

      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange (val) {
      this.pageBox.pageSize = val
      this.getList()
    },
    /* 分页切换 */
    handleCurrentChange (val) {
      this.pageBox.pageNum = val
      this.getList()
    },
    // 条件查询
    searchBtn () {
      this.getList()
    },
    // 查看详情
    taskInfo (row) {
      this.operateBox = {
        title: '任务详情',
        operate: 'info',
        paperId: row.paperId,
        id: row.id
      }
      this.editDialog = true
      this.timer = new Date().getTime()
    },
    // 任务分析
    analysis (row) {
      this.timer = new Date().getTime()
      this.analysisDialog = true
      this.operateBox.title = '数据分析'
      this.exameInfo = {
        allUser: row.userCount,
        complateUser: row.completedCount,
        planId: row.id,
        planName: row.title
      }
    }
  },
  watch: {}
}
</script>

<style scoped></style>

<template>
  <div class="main_content">
    <div class="title_info">{{ exameInfo.planName }}--试题选项选择数量分布统计</div>
    <!--<div class="operate_btn" >-->
    <!--<el-button type="primary" size="mini" icon="el-icon-download" @click="downloadFile">导出</el-button>-->
    <!--</div>-->
    <div class="box">
      <div class="one"></div>
      <div class="five">
        <el-row>
          <div>平均成绩:{{ scoreInfo.average }} 最高分:{{ scoreInfo.highestScore }} 最低分:{{ scoreInfo.lowestScore }}</div>
        </el-row>
        <el-row>
          <div>用户总数：{{ exameInfo.allUser }} 答题人数：{{ exameInfo.complateUser }}</div>
        </el-row>
      </div>
      <div class="six" />
    </div>
    <el-divider />
    <div v-for="(item, index) in wechatQuestions" :key="item.id" class="answer_box">
      <div v-if="item.typeId != 3">
        <h3>{{ index + 1 }}、{{ item.content }}</h3>
        <ul v-for="(citem, cindex) in item.wechatQuestionOptions" :key="citem.id" class="question_info">
          <li>{{ cindex + 1 }}、{{ citem.content }}</li>
          <el-slider v-model="citem.selectedNum" style="width: 80%" disabled />
          <span>{{ citem.selectedNum }}次</span>
        </ul>
      </div>
    </div>
    <el-divider />
    <div class="title_info">{{ exameInfo.planName }}--试题标签正答率统计</div>
    <div class="chart_box" id="line" />
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  name: 'analysis',
  props: {
    exameInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      scoreInfo: {},
      categoryVos: [],
      wechatQuestions: []
    }
  },
  created() {

  },
  mounted() {
    this.getAnalysisInfo()
  },
  methods: {
    // 获取考试分析模块
    async getAnalysisInfo() {
      try {
        const res = await api.getExameAnalysis({ planId: this.exameInfo.planId })
        if (res.code == 200) {
          console.log(res)
          this.scoreInfo = res.data.plan
          if (res.data.categoryVos && res.data.categoryVos.length > 0) {
            this.categoryVos = res.data.categoryVos
            const seriesData = []
            const xAxisData = []
            res.data.categoryVos.forEach(item => {
              seriesData.push(item.rate)
              xAxisData.push(item.categoryName)
            })
            console.log(seriesData, xAxisData)
            this.getMarker(seriesData, xAxisData)
          }
          if (res.data.paper.wechatQuestions) {
            this.wechatQuestions = res.data.paper.wechatQuestions
            console.log(this.wechatQuestions);
          }
        }
      } catch (e) {

      }
    },

    getMarker(seriesData, xAxisData) {
      var myChart = this.$echarts.init(document.getElementById('line'))
      myChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          },
          right: '20px'  // 距离容器右侧的距离
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            smooth: true
          }
        ]
      })
    },
    // 导出
    downloadFile() {

    }
  }
}
</script>

<style scoped lang="less">
.el-row {
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.main_content {
  height: 37.5rem;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: .5rem;
  }

  .title_info {
    font-size: 1rem;
    font-weight: bold;
    line-height: 2.25rem;
  }

  .answer_box {
    margin-bottom: 1.25rem;
    padding: 0 .625rem;

    h3 {
      font-weight: bold;
    }

    .question_info {
      display: flex;
      align-items: center;

      li {
        padding-right: 1.25rem;
      }

      span {
        padding-left: .625rem;
      }
    }
  }

  .chart_box {
    width: 94%;
    height: 18.75rem;
  }

  /deep/.el-slider__runway.disabled .el-slider__button {
    border-color: #0bf3c2;
  }
}
</style>

<template>
  <div class="main_box">
    <div class="content_box">
      <div class="operate_box">
        <div class="search_box">
          <div class="input_box">
            <el-input placeholder="姓名" v-model="searchForm.trueName"></el-input>
          </div>
          <div class="input_box">
            <el-select v-model="stateValue" clearable placeholder="状态">
              <el-option
                v-for="item in state"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="input_box"><el-button type="primary" class="el-icon-search" @click="serachBtn">搜索</el-button></div>
        </div>
        <div class="operate_btn" >
          <div class="score_box">
            <div>平均成绩：{{average}}</div>
            <div>最高分：{{highestScore}}</div>
            <div>最低分：{{lowestScore}}</div>
          </div>
        </div>
      </div>
      <div class="tbale_box">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          row-key="id"
          :highlight-current-row="true"
          :current-row-key="currentRow"
          height="100%">
          <el-table-column
            prop="id"
            label="ID"
            width="50">
          </el-table-column>
          <el-table-column
            prop="trueName"
            label="姓名"
            width="80">
          </el-table-column>
          <el-table-column
            prop="communityName"
            label="社区">
          </el-table-column>
          <el-table-column
            prop="communityId"
            label="社区ID"
            width="80">
          </el-table-column>
          <el-table-column
            prop="phoneNumber"
            label="手机号"
            width="100">
          </el-table-column>
          <el-table-column
            prop="examScore"
            label="分数"
            width="60">
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="答题时间"
            width="160">
          </el-table-column>
          <el-table-column
            prop="state"
            label="状态"
            width="60">
            <template slot-scope="scope">
              <span v-if="scope.row.completed">已完成</span>
              <span v-else>未完成</span>
            </template>
          </el-table-column>
          <el-table-column
            width="60"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="考试详情" placement="top">
                <el-button @click="details(scope.row)" type="primary" icon="el-icon-document" circle></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <new-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size=pageBox.pageSize
        layout="total,sizes, prev, pager, next"
        :total=pageBox.total>
      </new-pagination>
      <!--考试详情-->
    </div>
    <div class="info_box">
      <details-info :userInfo="userInfo"></details-info>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
import DetailsInfo from './details-info'
export default {
  name: 'edit-from',
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  components: { DetailsInfo },
  data () {
    return {
      stateValue: '',
      state: [
        {
          value: 1,
          label: '已考试'
        },
        {
          value: 2,
          label: '未考试'
        }
      ],
      tableData: [],
      searchForm: {
        trueName: '',
        completed: null,
        planId: ''
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      userInfo: {},
      timer: null,
      currentRow: null,
      average: 0,
      highestScore: 0,
      lowestScore: 0
    }
  },
  created () {
    if (this.operateBox.id != '') {
      this.searchForm.planId = this.operateBox.id
      this.getList()
    }
  },
  methods: {
    async getList () {
      if (this.stateValue == 1) {
        this.searchForm.completed = true
      }
      if (this.stateValue == 2) {
        this.searchForm.completed = false
      }
      if (!this.stateValue) {
        this.searchForm.completed = null
      }
      try {
        const res = await api.getTaskInfoList(this.searchForm, this.pageBox)
        if (res.code == 200 && res.data) {
          this.tableData = res.data.tableDataInfo.rows
          this.pageBox.total = res.data.tableDataInfo.total
          this.average = res.data.average
          this.highestScore = res.data.highestScore
          this.lowestScore = res.data.lowestScore
          this.userInfo = {
            planId: res.data.tableDataInfo.rows[0].planId,
            userId: res.data.tableDataInfo.rows[0].userId,
            paperId: this.operateBox.paperId,
            trueName: res.data.tableDataInfo.rows[0].trueName
          }
          this.currentRow = res.data.tableDataInfo.rows[0].id
        }
      } catch (e) {

      }
    },

    /* 一页显示多少条切换 */
    handleSizeChange (val) {
      this.pageBox.pageSize = val
      this.getList()
    },
    /* 分页切换 */
    handleCurrentChange (val) {
      this.pageBox.pageNum = val
      this.getList()
    },
    // 条件查询
    serachBtn () {
      this.getList()
    },
    details (row) {
      this.userInfo = {
        planId: row.planId,
        userId: row.userId,
        paperId: this.operateBox.paperId,
        trueName: row.trueName
      }
    }
  }
}
</script>

<style scoped lang="less">
  .main_box{
    height: 670px;
    display: flex;
    .content_box{
      height: 100%;
      flex: 1;
      .tbale_box{
        height: calc(100% - 95px)!important;
      }
    }
    .info_box{
      width: 40%;
    }
    .score_box{
      display: flex;
      justify-content: center;
      div{
        margin-left:10px;
      }
    }
  }

</style>
